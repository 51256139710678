import React from 'react';
import {Avatar, Card, Col, Form, Row} from "antd";
import LoginForm from "../components/Form/LoginForm";
import {LockOutlined} from "@ant-design/icons";

const LoginPage = () => {

    const [form] = Form.useForm();

    return (
        <Row justify={'center'}>
            <Col>
                <Card style={{ borderRadius: '1.5rem', marginTop: '15rem', maxWidth: '25rem', boxShadow: '0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%), 0 9px 28px 8px rgb(0 0 0 / 5%)'}}>
                    <Row justify={'center'} style={{marginBottom: '1.5rem'}}>
                        <Col>
                            <Avatar size={80} icon={<LockOutlined style={{color: 'black'}}/>} style={{backgroundColor: 'white'}}/>
                        </Col>
                    </Row>
                    <Row justify={'center'} align={'middle'}>
                        <Col>
                            <Form form={form}>
                                <LoginForm form={form}/>
                            </Form>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    );
};

export default LoginPage;