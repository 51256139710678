import React, {useState} from 'react';
import {Button, Col, Form, Input, Modal, Row, Typography} from "antd";
import {LockOutlined, MailOutlined, PlusOutlined, UserAddOutlined, UserOutlined} from '@ant-design/icons';
import {useDispatch} from "react-redux";
import {createIntUserAction} from "../../_redux/actions/InternalUserAction";

const ExternalUserAddModal = () => {
    const [form] = Form.useForm();
    const {Title} = Typography;
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();

    const openModal = () => {
        setShowModal(true)
        form.setFieldsValue({name: '', email: '', password: ''});
    }

    const onClickSubmit = () => {
        form.validateFields().then((value) => {
            dispatch(createIntUserAction({
                "name": value.name,
                "email": value.id,
                "password": value.password,
            }, setShowModal));
            form.resetFields();
        })
    }

    const handleCancel = () => {
        setShowModal(false);
        form.resetFields();
    }

    return (
        <>
            <div style={{textAlign: 'right', marginBottom: '1rem'}}>
                <Button style={{borderRadius: '0.5rem'}} onClick={openModal} icon={<PlusOutlined />}/>
            </div>
            <Modal
                title={<Title level={4} style={{margin: '0 0 2rem'}}><UserAddOutlined /> Add User</Title>}
                open={showModal}
                onCancel={handleCancel}
                width={300}
                footer={null}
                maskClosable={false}
            >
                <Form form={form} onFinish={onClickSubmit} layout='vertical'>
                    <Row>
                        <Col span={24}>
                            <Form.Item label='Name' name='name' rules={[{required: true, message: 'Please Input'}]}>
                                <Input placeholder={'Name'} prefix={<UserOutlined />}/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label='E-mail' name='id' rules={[{required: true, message: 'Please Input'}]}>
                                <Input placeholder={'E-mail'} prefix={<MailOutlined />}/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label='Password' name='password' rules={[{required: true, message: 'Please Input'}]}>
                                <Input.Password placeholder={'Password'} prefix={<LockOutlined />}/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item style={{marginBottom: '0.5rem'}}>
                                <Button style={{marginTop: '2rem', width: '100%', height: '2rem', borderRadius: '0.5rem'}} type='primary' htmlType='submit'>Save</Button>
                            </Form.Item>
                        </Col>

                        {/*<Col span={24}>*/}
                        {/*    <Form.Item label='Role' name='role' rules={[{required: true, message: ''}]}>*/}
                        {/*        <Select placeholder={'Select Role'}>*/}
                        {/*            <Option value={'role1'} key={1}/>*/}
                        {/*            <Option value={'role2'} key={2}/>*/}
                        {/*            <Option value={'role3'} key={3}/>*/}
                        {/*        </Select>*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}

                    </Row>
                </Form>
            </Modal>

        </>
    );
};

export default ExternalUserAddModal;