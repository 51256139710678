import React from 'react';
import {Table} from "antd";

const InternalUserTable = ({userList}) => {

    const columns = [
        {title: 'Name', dataIndex: 'name', align: 'center'},
        {title: 'E-mail', dataIndex: 'id', align: 'center'},
        // {title: 'Role', dataIndex: 'role', align: 'center'},
    ]


    return (
        <Table columns={columns} dataSource={userList} rowKey="id"/>
    );
};

export default InternalUserTable;