import axios from "axios";
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
    DOWNLOAD_FILE_FAILURE,
    DOWNLOAD_FILE_REQUEST, DOWNLOAD_FILE_SUCCESS,
    LOAD_FILES_FAILURE,
    LOAD_FILES_REQUEST,
    LOAD_FILES_SUCCESS
} from "../actions/fileAction";


function loadFileListAPI(data) {
    return axios.get(`/upload-file/list/${data}`);
}

function* loadFileList(action) {
    try {
        const response = yield call(() => loadFileListAPI(action.data));
        yield put({
            type: LOAD_FILES_SUCCESS,
            payload: response.data,
        })
    } catch (err) {
        yield put({
            type: LOAD_FILES_FAILURE,
        });
    }
}

function* watchLoadFileList() {
    yield takeLatest(LOAD_FILES_REQUEST, loadFileList);
}

// File Download
function downloadFileAPI(data) {
    return axios.get(`/upload-file/url/${data.id}`)
        .then(response => {
            const url = response.data;
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(err => {
            console.log(err);
        })
}

function* downloadFile(action) {
    yield call(() => downloadFileAPI(action.data));
    try {
        yield put({
            type: DOWNLOAD_FILE_SUCCESS,
        })
    } catch (err) {
        yield put({
            type: DOWNLOAD_FILE_FAILURE,
        });
    }
}

function* watchDownloadFIle() {
    yield takeLatest(DOWNLOAD_FILE_REQUEST, downloadFile);
}


export default function* fileSaga() {
    yield all([
        fork(watchLoadFileList),
        fork(watchDownloadFIle),
    ])
}