import React from 'react';
import {useHistory} from "react-router-dom";
import {Button, Result} from "antd";

const NotFoundPage = () => {

    const history = useHistory();
    const onClickPage = () => {
        history.push('/main');
    }

    return (
        <Result
            status='404'
            title='404'
            subTitle='Sorry, the page does not exist.'
            extra={<Button style={{borderRadius: '0.5rem'}} type='primary' onClick={onClickPage}>Back Home</Button> }
        />
    );
};

export default NotFoundPage;