import React, {useState} from 'react';
import {Avatar, Card, Col, Divider, Popover, Row, Space, Typography} from "antd";
import {MailOutlined, SettingOutlined, UserOutlined} from "@ant-design/icons";
import styled from "styled-components";
import {useSelector} from "react-redux";
import ChangePasswordModal from "../../../components/Modal/ChangePasswordModal";

const StyledCard = styled(Card)`
    .ant-card-body {
        background-color: #001529;
    }
    .ant-card-meta-title {
        color: #dcdcdc;
    }
    .ant-card-meta-description {
        color: #787e6c;
    }
`;

const UserProfile = () => {

    const {Meta} = Card;
    const {Title, Paragraph, Link} = Typography;
    const [showProfile, setShowProfile] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const userInfo = useSelector(state => state.auth['info']);

    const onClickProfile = () => {
        setShowProfile(true);
    }

    const onClickChangePw = () => {
        setShowEdit(true);
        setShowProfile(false);
    }

    const handleOpenChange = (newOpen) => {
        setShowProfile(newOpen);
    }

    return (
        <>
            <StyledCard bordered={false}>
                <Meta avatar={
                    <Popover
                        style={{width: '100rem'}}
                        content={
                            <>
                                {/*<Divider style={{margin: '0 0 1rem'}}>Profile</Divider>*/}

                                <Space direction='vertical'>
                                    <Space split={<Divider type='vertical'/>}>
                                        <UserOutlined />
                                        <Title style={{margin: '0 0 3px'}} level={5}>{userInfo.name}</Title>
                                    </Space>
                                    <Space split={<Divider type='vertical'/>}>
                                        <MailOutlined />
                                        <Title style={{margin: '0 0 3px'}} level={5}>{userInfo.id}</Title>
                                    </Space>
                                    <Space split={<Divider type='vertical'/>}>
                                        <SettingOutlined />
                                        <Title style={{margin: '0 0 3px'}} level={5}><Link onClick={onClickChangePw}>Change Password</Link></Title>
                                    </Space>
                                </Space>

                                {showEdit === true ? <ChangePasswordModal userInfo={userInfo} visible={showEdit} setVisible={setShowEdit}/> : null}
                            </>
                        }
                        trigger='click'
                        open={showProfile}
                        onOpenChange={handleOpenChange}
                        placement='bottom'
                    >
                        <Avatar icon={<UserOutlined/>} style={{backgroundColor: "gray"}} size='small'/>
                    </Popover>
                }
                      title={<a style={{color: 'white'}}
                                onClick={onClickProfile}>{userInfo.name}</a> }
                      style={{backgroundColor: ''}}/>
            </StyledCard>
            {/*{showProfile === true ? <ProfileDrawer userInfo={userInfo} visible={showProfile} setVisible={setShowProfile}/> : null}*/}
        </>
    );
};

export default UserProfile;