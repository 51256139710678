import {useEffect} from 'react';
import axios from "axios";
import {message} from "antd";

const AxiosInterceptor = ({children}) => {

    axios.defaults.baseURL = process.env.REACT_APP_API_URL === undefined ? 'https://upload.imagetrial.com' : process.env.REACT_APP_API_URL;
    axios.defaults.headers['X-AUTH-TOKEN'] = window.localStorage.getItem('token');

    useEffect(() => {
        const resInterceptor = response => {
            return response;
        }

        const errInterceptor = error => {
            if (error.response.status === 403) {
                message.error({content: 'Please check ID or Password.'}).then();
            }
            if (error.response.status === 409) {
                message.error({content: 'You are already a registered user.'}).then();
            }
            if (error.response.status === 500) {
                message.error({content: 'Please contact the administrator.'}).then();
            }
            return Promise.reject(error);
        }

        const interceptor = axios.interceptors.response.use(resInterceptor, errInterceptor);

        return () => axios.interceptors.response.eject(interceptor);

    }, []);
    return children;
};

export {AxiosInterceptor};