import React, {useEffect} from 'react';
import {Card, Divider, Typography} from "antd";
import InternalUserAddModal from "../components/Modal/InternalUserAddModal";
import InternalUserTable from "../components/Table/InternalUserTable";
import {useDispatch, useSelector} from "react-redux";
import {loadIntUserAction, loadUserAction} from "../_redux/actions/InternalUserAction";
import {TeamOutlined} from "@ant-design/icons";

const AdminPage = () => {

    const dispatch = useDispatch();
    const {Title} = Typography;
    const userList = useSelector(state => state.user['intUserList']);
    const auth = useSelector(state => state.auth['auth']);

    useEffect(() => {
        if (auth === true) {
            dispatch(loadIntUserAction());
        }
    }, [auth])

    return (
        <>
            <Divider orientation='left'>
                <Title level={3} style={{margin: 0}}><TeamOutlined /> User List</Title>
            </Divider>
            <Card style={{borderRadius: '1rem'}}>
                <InternalUserAddModal/>
                <InternalUserTable userList={userList}/>
            </Card>
        </>
    );
};

export default AdminPage;