import React, {useEffect, useState} from 'react';
import ExternalUserTable from "../components/Table/ExternalUserTable";
import {Card, Divider, Typography} from "antd";
import ExternalUserAddModal from "../components/Modal/ExternalUserAddModal";
import {useDispatch, useSelector} from "react-redux";
import {loadExtUserAction} from "../_redux/actions/ExternalUserAction";
import {TeamOutlined} from "@ant-design/icons";

const MainPage = () => {

    const dispatch = useDispatch();
    const {Title} = Typography;
    const extUserList = useSelector(state => state.user['extUserList']);
    const uploadURL = useSelector(state => state.user['uploadURL']);
    const auth = useSelector(state => state.auth['auth']);
    const nowDate = new Date();

    useEffect(() => {
        if (auth === true) {
            dispatch(loadExtUserAction());
        }
    }, [auth])

    return (
        <>
            <Divider orientation='left'>
                <Title level={3} style={{margin: 0}}><TeamOutlined /> Uploader List</Title>
            </Divider>
            <Card style={{borderRadius: '1rem'}}>
                <ExternalUserAddModal/>
                <ExternalUserTable extUserList={extUserList} uploadURL={uploadURL}/>
            </Card>
        </>
    );
};

export default MainPage;