import React, {useRef, useState} from 'react';
import {Button, DatePicker, Input, message, Space, Table} from "antd";
import FileListModal from "../Modal/FileListModal";
import {FileSearchOutlined, LinkOutlined, MailOutlined, SearchOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {loadFileList} from "../../_redux/actions/fileAction";
import dayjs from "dayjs";

const ExternalUserTable = ({extUserList}) => {


    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const fileList = useSelector(state => state.file['fileList']);
    const today = new Date();
    const searchInput = useRef(null);

    const onClickFileList = (row) => {
        dispatch(loadFileList(row.id));
        setShowModal(true);
    }
    const onClickCopy = (row) => {
        navigator.clipboard.writeText(row.url).then();
        message.success({content: 'URL Copied !'}).then();
    }
    const onClickSendMail = () => {
        message.success({content: 'Send Mail'}).then();
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }

    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        confirm();
        setSearchText('');
    }

    const getColumnSearch = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{padding: 10}} onKeyDown={e => e.stopPropagation()}>
                <Input
                    style={{marginBottom: 8, display: 'block'}}
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size='small'
                        style={{width: 90}}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, confirm)}
                        size='small'
                        style={{width: 90}}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        render: text => text
    })


    const columns = [
        {title: 'Status', align: 'center', width: '50px', sorter: (a, b) => new Date(a.eDate) - new Date(b.eDate), render: (row) => (
                <div style={{margin: 'auto', width: '10px', height: '10px', borderRadius: '50%', backgroundColor: new Date(row.eDate) > today ? '#00bf00' : '#ff3200'}}/>
            )},
        {title: 'User Name', dataIndex: 'userName', align: 'center', ...getColumnSearch('userName')},
        {title: 'Start Date', width: '15%', dataIndex: 'sDate', align: 'center', filters: extUserList.map(v => {return {text: v.sDate, value: v.sDate}}), onFilter: (value, record) => record.sDate.indexOf(value) === 0, filterSearch: true},
        {title: 'End Date', width: '15%', dataIndex: 'eDate', align: 'center', filters: extUserList.map(v => {return {text: v.eDate, value: v.eDate}}), onFilter: (value, record) => record.eDate.indexOf(value) === 0, filterSearch: true},
        {title: 'File List', width: '10%', align: 'center', render: (row) => (
                <Button style={{borderRadius: '0.5rem'}} onClick={() => onClickFileList(row)} icon={<FileSearchOutlined />}/>
            )},
        {title: 'URL', width: '10%', align: 'center', render: (row) => (
                <Button style={{borderRadius: '0.5rem'}} onClick={() => onClickCopy(row)} icon={<LinkOutlined />}/>
            )},
        {title: 'Send Mail', width: '10%', dataIndex: 'sendMail', align: 'center', render: (row) => (
                <Button disabled={true} style={{borderRadius: '0.5rem'}} onClick={onClickSendMail} icon={<MailOutlined />}/>
            )},
    ]


    return (
        <>
            <Table columns={columns} dataSource={extUserList} rowKey='id'/>
            <FileListModal fileList={fileList} showModal={showModal} setShowModal={setShowModal}/>
        </>
    );
};

export default ExternalUserTable;