import React, {useState} from 'react';
import {Button, Col, Form, Input, InputNumber, Modal, Row, Select, Space, Typography} from "antd";
import {ClockCircleOutlined, MailOutlined, PlusOutlined, UserAddOutlined} from '@ant-design/icons';
import {useDispatch} from "react-redux";
import {createExtUserAction} from "../../_redux/actions/ExternalUserAction";

const ExternalUserAddModal = () => {
    const [form] = Form.useForm();
    const {Title} = Typography;
    const {Option} = Select;
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();

    const openModal = () => {
        setShowModal(true)
    }

    const onClickSubmit = () => {
        form.validateFields().then((value) => {
            if (value.type === 'day') {
                const time = String(value.expireTime * 24);
                dispatch(createExtUserAction({
                    "uploadUserEmail": value.email,
                    "expireTime": time,
                }, setShowModal));
                form.resetFields();
                return;
            }
            dispatch(createExtUserAction({
                "uploadUserEmail": value.email,
                "expireTime": value.expireTime,
            }, setShowModal));
            form.resetFields();
        })
    }

    const handleCancel = () => {
        setShowModal(false);
        form.resetFields();
    }

    const selectAfter = (
        <Select style={{width: 80}}>
            <Option value="hour">Hours</Option>
            <Option value="day">Days</Option>
        </Select>
    )

    return (
        <>
            <div style={{textAlign: 'right', marginBottom: '1rem'}}>
                <Button style={{borderRadius: '0.5rem'}} onClick={openModal} icon={<PlusOutlined />}/>
            </div>
            <Modal
                title={<Title level={4} style={{margin: '0 0 2rem'}}><UserAddOutlined /> Add User</Title>}
                open={showModal}
                onCancel={handleCancel}
                width={300}
                footer={null}
                maskClosable={false}
            >
                <Form form={form} onFinish={onClickSubmit} layout='vertical'>
                    <Row>
                        <Col span={24}>
                            <Form.Item label='E-mail' name='email' rules={[{required: true, message: 'Please Input'}]}>
                                <Input placeholder={'E-mail'} prefix={<MailOutlined />}/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label='Upload Expire Time' name='expireTime' rules={[{required: true, message: 'Please Input'}]}>
                                {/*<InputNumber style={{width: '100%'}} min={1} max={120} placeholder={'Hour (1 ~ 120)'} prefix={<ClockCircleOutlined />}/>*/}
                                <InputNumber placeholder={'hours | days'} prefix={<ClockCircleOutlined />} min={1} addonAfter={<Form.Item initialValue='hour' noStyle name='type'>{selectAfter}</Form.Item>}/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item style={{marginBottom: '0.5rem'}}>
                                <Button style={{marginTop: '2rem', width: '100%', height: '2rem', borderRadius: '0.5rem'}} type='primary' htmlType='submit'>Save</Button>
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </Modal>

        </>
    );
};

export default ExternalUserAddModal;