import {
    DOWNLOAD_FILE_FAILURE,
    DOWNLOAD_FILE_REQUEST,
    DOWNLOAD_FILE_SUCCESS,
    LOAD_FILES_FAILURE,
    LOAD_FILES_REQUEST,
    LOAD_FILES_SUCCESS
} from "../actions/fileAction";
import {message} from "antd";

export const initialState = {
    fileList: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_FILES_REQUEST:
            return {
                ...state,
            }
        case LOAD_FILES_SUCCESS:
            return {
                ...state,
                fileList: [...action.payload]
            }
        case LOAD_FILES_FAILURE:
            return {
                ...state,
            }

        case DOWNLOAD_FILE_REQUEST:
            return {
                ...state,
            }
        case DOWNLOAD_FILE_SUCCESS:
            message.success({content: 'File download'}).then();
            return {
                ...state,
            }
        case DOWNLOAD_FILE_FAILURE:
            message.error({content: 'File download Error !'}).then();
            return {
                ...state,
            }

        default:
            return {
                ...state
            }
    }
}

export default reducer;

