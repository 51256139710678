import React, {useState} from 'react';
import {Button, Modal, Table, Typography} from "antd";
import {CloudDownloadOutlined, FileSearchOutlined, RetweetOutlined} from "@ant-design/icons";
import {useDispatch} from "react-redux";
import {fileDownload} from "../../_redux/actions/fileAction";
import dayjs from "dayjs";

const FileListModal = ({fileList, showModal, setShowModal}) => {

    const dispatch = useDispatch();
    const {Title} = Typography;

    const handleCancel = () => {
        setShowModal(false);
    }

    const onClickDownload = (row) => {
        dispatch(fileDownload(row));
    }

    const columns = [
        {title: 'File Name', dataIndex: 'name', align: 'center'},
        {title: 'Upload Date / Time', width: '30%', dataIndex: 'date', align: 'center', render: (row) => dayjs(row).format('YYYY-MM-DD / HH:mm:ss')},
        {title: 'Download', width: '10%', align: 'center', render: (row) => (
                <Button type='link' size='small' onClick={() => onClickDownload(row)} icon={<CloudDownloadOutlined style={{fontSize: '1.5rem'}}/>}/>
            )},
    ]


    return (
        <>
            <Modal
                title={<Title level={4} style={{margin: '0 0 2rem'}}><FileSearchOutlined /> File List</Title>}
                open={showModal}
                footer={null}
                onCancel={handleCancel}
                width={800}
                maskClosable={false}
            >
                <Table columns={columns} dataSource={fileList} rowKey='id'/>
            </Modal>
        </>
    );
};

export default FileListModal;